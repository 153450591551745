import { ValidationError } from ".";

export const defaultLng = "en";

export type LanguageTranslations = Record<ValidationError, string>;
export type Translations = Record<string, LanguageTranslations>;

export const defaultTranslations: Translations = {
  fr: {
    invalid_email: "Adresse mail non valide",
    disposable_domain: "Utilisez votre adresse personnelle",
    rejected: "Adresse refusée, contactez le support",
  },
  en: {
    invalid_email: "Invalid email syntax",
    disposable_domain: "Use your personal email address",
    rejected: "Rejected email address, contact the support",
  },
};

export const translate = (
  str: ValidationError,
  lng: string = defaultLng,
  translations?: Translations
): string => {
  return (
    translations?.[lng]?.[str] ??
    defaultTranslations[lng]?.[str] ??
    translations?.[defaultLng]?.[str] ??
    defaultTranslations[defaultLng]?.[str] ??
    "Unknown error"
  );
};
