import {
  defaultLng,
  defaultTranslations,
  translate,
  Translations,
} from "./translation";
import "regenerator-runtime/runtime";

module["hot"]?.accept();

export type ValidationError =
  | "invalid_email"
  | "disposable_domain"
  | "rejected";

export type EmailValidation = {
  email: string;
  isValid: boolean;
  error?: ValidationError;
  errorMessage?: string;
};

const parseReason = (str: string): [mainError: number, subError: number] => {
  const res = str.match(/.*\(([0-9]+)\.([0-9]+)\)/);
  if (res) {
    return [~~res[1], ~~res[2]];
  }
};

const wellKnownDisposableDomains = ["yopmail.com", "yopmail.fr", "example.com"];

const cacheResponses: Map<
  string,
  EmailValidation | Promise<EmailValidation>
> = new Map();

const validationUrl = "https://api.dmood.org/1/validate/";

class EmailValidator {
  private lng: string = "en";
  private translations: Translations;
  private allowDisposable = false;

  public readonly form?: HTMLFormElement;
  public readonly emailInput?: HTMLInputElement;
  public readonly submitButton?: HTMLButtonElement;
  private onError: (message: string, error: ValidationError) => void;
  private onBeforeValidation?: (event: Event) => boolean;

  constructor(
    settings: {
      lng?: string;
      translations?: Translations;
      allowDisposable?: boolean;
      form?: {
        selector: string;
        onError: (message: string, error: ValidationError) => void;
        onBeforeValidation?: (event: Event) => boolean;
      };
    } = {}
  ) {
    this.lng = settings.lng ?? defaultLng;
    this.translations = settings.translations ?? defaultTranslations;
    this.allowDisposable = !!settings.allowDisposable;

    if (settings.form) {
      const { selector, onError, onBeforeValidation } = settings.form;
      this.form = document.querySelector<HTMLFormElement>(selector);
      if (!this.form) {
        throw new Error(`${selector} doesn't exists in DOM`);
      }

      this.emailInput = this.form.querySelector<HTMLInputElement>(
        `input[type=email]`
      );
      if (!this.emailInput) {
        throw new Error("Need an `input [type=email]` in form");
      }

      this.submitButton = this.form.querySelector<HTMLButtonElement>(
        `[type=submit]`
      );
      if (!this.submitButton) {
        throw new Error("Need an `[type=submit]` in form");
      }

      this.onError = onError;
      this.onBeforeValidation = onBeforeValidation;
      this.form.addEventListener("submit", this.onSubmit);
    }
  }

  private onSubmit = async (event: Event): Promise<void> => {
    try {
      if (this.onBeforeValidation?.(event) === false) {
        return;
      }
      event.preventDefault();
      this.form.classList.add("email-validator-running");
      const result = await this.validate(this.emailInput.value);
      this.form.classList.remove("email-validator-running");
      if (!result.isValid) {
        return this.onError(result.errorMessage, result.error);
      }
    } catch (err) {
      console.error(err);
    }
    setTimeout(() => this.form.submit());
  };

  public async validate(email: string): Promise<EmailValidation> {
    email = email?.trim().toLowerCase();
    const domain = email?.split("@")[1];
    if (!email || !domain) {
      return {
        email,
        isValid: false,
        errorMessage: this.translate("invalid_email"),
        error: "invalid_email",
      };
    } else if (wellKnownDisposableDomains.includes(domain)) {
      return {
        email,
        isValid: this.allowDisposable,
        errorMessage: this.translate("disposable_domain"),
        error: "disposable_domain",
      };
    }

    if (!cacheResponses.has(email)) {
      let requestTimeout: any;
      const makeRequest = async (): Promise<EmailValidation> => {
        const response: EmailValidation = { email, isValid: true };
        try {
          const requestResponse = await fetch(
            `${validationUrl}${encodeURIComponent(email)}`
          );
          if (requestResponse.ok) {
            const data = (await requestResponse.json()) as {
              email: string;
              status: "unknown" | "allow" | "soft-deny" | "deny";
              reason?: string;
            };

            if (data.status === "deny") {
              response.isValid = false;
              const [mainError, subError] = parseReason(data.reason);
              if (mainError === 1) {
                response.error = "invalid_email";
              } else if (mainError === 2) {
                response.error = "disposable_domain";
                response.isValid = this.allowDisposable;
              } else {
                response.error = "rejected";
              }
              response.errorMessage = this.translate(response.error);
            }

            cacheResponses.set(email, response);
          } else {
            throw new Error(`bad status ${requestResponse.status}`);
          }
        } catch (err) {
          console.error(err);
          cacheResponses.delete(email);
        }
        clearTimeout(requestTimeout);
        return response;
      };
      cacheResponses.set(
        email,
        Promise.race<EmailValidation>([
          makeRequest(),
          new Promise<EmailValidation>((resolve) => {
            requestTimeout = setTimeout(() => {
              console.error("request timed out");
              cacheResponses.delete(email);
              resolve({ email, isValid: true });
            }, 4500);
          }),
        ])
      );
    }

    return await cacheResponses.get(email);
  }

  private translate(str: ValidationError): string {
    return translate(str, this.lng, this.translations);
  }
}

window["EmailValidator"] = EmailValidator;
